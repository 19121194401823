@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

body {
  font-family: 'Inter', Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #06141C;
  color: #FFFFFF;
}

h2 {
  font-family: 'Inter', Tahoma;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}


.app-input-group {
  .input-group-text {
    background-color: #092235;
    color: rgba(255, 255, 255, 0.36);
    border: 0;
    padding: 21px 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .form-control {
    background-color: #092235;
    color: #FFFFFF;
    border: 0;
    padding: 21px 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.app-radius-14 {
  border-radius: 14px;
}

.app-card {
  background: #092235;
  border-radius: 14px;
  cursor: pointer;
  .app-card-padding {
    padding: 80px 20px;
  }
}

.app-margin-y-130 {
  margin-top: 130px;
  margin-bottom: 130px;
  @media (max-width: 767px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
